<template>
  <div class="chats">
    <div v-for="(msgGrp, index) in formattedChatData.formattedChatLog" :key="msgGrp.senderId + String(index)"
      class="chat position-relative" :class="{ 'chat-left': msgGrp.senderId === formattedChatData.contact.hashid }">

      <div class="separator" v-if="msgGrp.date">{{ msgGrp.date }}</div>
      <div class="chat-info  pt-50 pb-0">
        
          <div
            class="chat-content mt-0 mb-50" v-for="(msgData, ind) in msgGrp.messages" :key="msgData.time + String(ind)">
              <div class="chat-avatar mr-75">
                <b-avatar size="30" class="avatar-border-2 box-shadow-1 mt-25" variant="transparent"
                  :src="msgGrp.senderId === formattedChatData.contact.hashid ? formattedChatData.contact.avatar : profileUserAvatar" :to="{
                    name: 'employee-profile',
                    params: { id: msgGrp.senderId },
                  }" target='_blank' />
              </div>
              <div class="chat-body m-0">
              <h6 class="mb-0" >
                  {{ msgGrp.senderId === formattedChatData.contact.hashid ? formattedChatData.contact.name : userData.name
                  }}<span class="ml-1 chat-time">{{ msgGrp.messages[0].time }}</span>
                </h6>
            <!-- Replied Message Design -->
              <div class="replied-msg mb-25" v-if="msgData.parent">
                <p v-html="msgData.parent.body" class="mb-0" style="letter-spacing: 1px; font-size: 14px"></p>
              </div>
            <!-- End Replied Message Design -->
            <div>
              <p v-html="msgData.message" class="mb-0" style="letter-spacing: 1px; font-size: 14px"></p>
              <!-- Hover Action -->
              <div class="message-actions-wrapper">
                <div class="options-container">
                  <!-- <button @click="toogleDialogEmoji($event)"> -->
                  <!-- <b-img :src="require('@/assets/images/icons/smile-icon.svg')" width="28"></b-img> -->
                  <!-- <VEmojiPicker :pack="emojisNative" labelSearch="Search" @select="onSelectEmoji" class="emoji-picker"
                            :hidden="dialogHidden" /> -->
                  <b-img :src="require('@/assets/images/icons/reply-icon.svg')" width="30"
                    @click="$emit('reply-to', msgData)" class="reply-chaticon"  v-b-tooltip.hover.v-default title="Reply Back"></b-img>
                  <feather-icon icon="BookmarkIcon" :class="msgData.youSaved ? 'saved-post' : 'profile-icon'" :id="msgData.hashid" stroke="#6E6B7B" size="28" v-b-tooltip.hover.v-default
                    title="Save Message" @click="saveMessage(msgData)" />
                    <!-- v-b-toggle.sidebar-left -->
                  <div class="dropdown" >
                    <b-dropdown variant="link" no-caret toggle-class="p-0" right>
                      <template #button-content>
                        <feather-icon icon="MoreVerticalIcon" size="24" stroke="#6E6B7B" class="text-body align-middle"
                          v-b-tooltip.hover.v-default title="More" v-if="msgData.senderId == userData.hashid" />
                      </template>
                      <b-dropdown-item v-if="msgData.senderId == userData.hashid" @click="$emit('edit-message', msgData)">
                        <feather-icon icon="EditIcon" size="24" class="mr-25 text-default" stroke="#6E6B7B" />
                        <span class="">Edit</span>
                      </b-dropdown-item>
                      <b-dropdown-item v-if="msgData.senderId == userData.hashid" @click="confirmDeleteRecord(msgData)">
                        <feather-icon icon="TrashIcon" size="24" class="mr-25 text-default" stroke="#6E6B7B" />
                        <span class="">Delete</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- Reply Chat -->
      <b-form v-if="editMessageId" class="chat-app-form" @submit.prevent="sendMessage">
        <b-input-group class="input-group-merge form-send-message mr-1">
          <b-form-textarea v-model="chatInputMessage" placeholder="Enter your message" />
          <b-row class="msg-attach">
            <div class="left-option">
              <b-img :src="require('@/assets/images/icons/smile-icon.svg')" width="22"></b-img>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clip-path="url(#clip0_6544_28694)">
                  <path d="M15 8H15.0096" stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path
                    d="M3 6C3 5.20435 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3H18C18.7956 3 19.5587 3.31607 20.1213 3.87868C20.6839 4.44129 21 5.20435 21 6V18C21 18.7956 20.6839 19.5587 20.1213 20.1213C19.5587 20.6839 18.7956 21 18 21H6C5.20435 21 4.44129 20.6839 3.87868 20.1213C3.31607 19.5587 3 18.7956 3 18V6Z"
                    stroke="#6E6B7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M3 16.0018L8 11.0018C8.928 10.1088 10.072 10.1088 11 11.0018L16 16.0018" stroke="#6E6B7B"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M14 13.9979L15 12.9979C15.928 12.1049 17.072 12.1049 18 12.9979L21 15.9979" stroke="#6E6B7B"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_6544_28694">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <b-img :src="require('@/assets/images/icons/mic-icon.svg')" width="17"></b-img>
            </div>
            <div class="sent-msg">
              <b-button variant="primary" type="submit" pill>
                <b-img :src="require('@/assets/images/icons/post-cmt-white.svg')" width="18"></b-img>
              </b-button>
            </div>
          </b-row>
        </b-input-group>
      </b-form>
      <!-- End Reply Chat -->

    </div>

    <!-- New Chat -->
    <div class="empty-chat w-100" v-if="formattedChatData.formattedChatLog.length == 0">
      <div class="d-flex align-items-center profile-img mb-50">
        <b-img :src="formattedChatData.contact.avatar" width="28"></b-img>
        <div class="d-flex flex-column ml-75">
          <strong>{{ formattedChatData.contact.name }}</strong>
          <!-- <h6>{{formattedChatData.contact.name}}</h6> -->
        </div>
      </div>
      <p class="user-description mb-50">Check out their profile to learn more about them.</p>
      <b-button variant="outline-secondary" rounded :to="{
        name: 'employee-profile',
        params: { id: formattedChatData.contact.hashid },
      }" target='_blank'>View Profile</b-button>
    </div>
    <!-- End New Chat -->
  </div>
</template>
  
<script>
import { computed } from '@vue/composition-api';
import { BAvatar, BModal, BImg, BButton } from 'bootstrap-vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import $ from "jquery";
import QuillInput from "@/components/QuillInput.vue";
export default {
  components: {
    BAvatar, ToastificationContent, BModal, BButton,
    BImg,
    QuillInput
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    profileUserAvatar: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const formattedChatData = computed(() => {
      const contact = {
        hashid: props.chatData.contact.hashid,
        avatar: props.chatData.contact.avatar,
        name: props.chatData.contact.name
      }

      let chatLog = []
      if (props.chatData.chat) {
        chatLog = props.chatData.chat.messages
      }
      var formattedChatLog = []
      if (chatLog && chatLog[0]) {
        let chatMessageSenderId = chatLog[0].senderId + '-' + chatLog[0].created_at;
        let msgGroup = {
          senderId: chatLog[0].senderId,
          messages: [],
          date: chatLog[0].date,
        }
        var day = chatLog[0].date;
        chatLog.forEach((msg, index) => {
          if (chatMessageSenderId === msg.senderId + '-' + msg.created_at) {
            msgGroup.messages.push(msg)
          } else {
            chatMessageSenderId = msg.senderId + '-' + msg.created_at;
            formattedChatLog.push(msgGroup)
            msgGroup = {
              senderId: msg.senderId,
              date: (day != msg.date) ? msg.date : null,
              messages: [msg],
            }
            day = msg.date;
          }
          if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
        })
      }
      // console.log(formattedChatLog)

      return {
        formattedChatLog,
        contact,
        profileUserAvatar: props.profileUserAvatar,
      }
    })
    return {
      formattedChatData,
    }
  },
  computed: {
    emojisNative() {
      return packEmoji;
    },
    pinnedPosts() {
      if (this.posts && this.posts.length) {
        var pinPosts = [];
        this.posts.forEach((post) => {
          if (post.is_pinned == "true") {
            pinPosts.push(post);
          }
        });
        const chunkSize = 2;
        var data = [];
        for (let i = 0; i < pinPosts.length; i += chunkSize) {
          const chunk = pinPosts.slice(i, i + chunkSize);
          data.push(chunk);
        }
        return data;
      }
      return [];
    },

  },
  mounted() {
    $(document).ready(function () {
      $(".chat").on("click", function (e) {
        e.preventDefault();
        $(".chat").removeClass('reply-active');
        $(this).addClass('reply-active');
      });
      // $(".reply-chaticon").click(function(event) {
      //   $(event.target).closest(".ps-container.user-chats").addClass("current");
      // });
      // $(".cursor-pointer").on("click", function (e) {
      //   e.preventDefault();
      //   $(".ps-container.user-chats").removeClass('current');
      // });
      $(document).on("click", ".reply-chaticon", function () { 
        $(".ps-container.user-chats").addClass("active-reply");
    });  


    $(document).on("click", ".reply-close", function () {
        if ($(".ps-container.user-chats").hasClass("active-reply")){
            $(".ps-container.user-chats").removeClass("active-reply");
        }
    });
    });
  },
  methods: {
    triggerEdit(message) {
      console.log(message);
    },
    confirmCloseButton() {
      this.$swal({
        title: "Are you sure?",
        text: "You are about to exit this form , if you proceed the form data will be lost.",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$bvModal.hide("create-custompop");
          this.post_form_data = {
            title: '',
            channel_type: "community",
            post_anonymously: false,
            feedback_type: "",
            body: "",
            scheduled_time: moment().add(1, "days").format("YYYY-MM-DD ") + "09:30",
            scheduled_timezone: this.getUserTimezone(),
            is_scheduled: false,
            other_details:{
              post_type:'text-post',
              author:''
            }
          };
          this.myFiles = [];
          this.page2 = false;
        }
      });
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure you want to delete this message? This cannot be undone.",
        text: `Message : "` + data.message.replace(/<\/?[^>]+(>|$)/g, "") + `"`,
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteMessage(data.hashid);
        }
      });
    },
    deleteMessage(id) {
      this.$store
        .dispatch("chats/removeMessage", id)
        .then((res) => {
          if (res.data.success) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Message Deleted",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: "Message deleted successfully",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Message Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Message deleted successfully',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
            for (let index = 0; index < this.chatData.chat.messages.length; index++) {
              const element = this.chatData.chat.messages[index];
              // console.log(element.hashid + " - " + id);
              // console.log(element.hashid == id);
              if (element.hashid == id) {
                // Remove the element at the specified index
                this.chatData.chat.messages.splice(index, 1);
              }
            }

          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Failed to delete message",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to delete message',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    onSelectEmoji(dataEmoji) {
      // this.data_local.title = this.data_local.title + dataEmoji.emoji;
      const input = document.getElementById('title');
      const cursorPosition = input.selectionStart;
      const currentValue = input.value;
      const smiley = dataEmoji.emoji;  // You can replace this with any smiley you want

      const newValue = currentValue.slice(0, cursorPosition) + smiley + currentValue.slice(cursorPosition);
      input.value = newValue;
      this.data_local.title = newValue;

      // Set the cursor position after the inserted smiley
      input.selectionStart = cursorPosition + 2;  // 2 is the length of the smiley
      input.selectionEnd = cursorPosition + 2;
      this.toogleDialogEmoji();
    },
    toogleDialogEmoji($event = null) {
      if ($event) {
        $event.preventDefault();
      }
      this.dialogHidden = !this.dialogHidden;
    },
    saveMessage(Message) {
      var element = document.getElementById(Message.hashid);
      if (Message.youSaved || element.classList.value.includes('saved-post')) {
        this.$http.delete(`/saved-models/${Message.hashid}/unsave/message`).then((res) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Message removed from saved messages",
          //     icon: "XCircleIcon",
          //     variant: "danger",
          //   },
          // });

          this.$toast({
          component: ToastificationContent,
          props: {
            title: `Message removed from saved messages`,
            icon:'XCircleIcon',
            variant : 'danger',
            hideClose: true,
            
          },
              },
              {
          timeout : 3000,
          position : 'bottom-center',
          toastClassName:"error-info",
          hideProgressBar : true,
              })
        });

        element.classList.remove('saved-post');
        element.classList.add('profile-icon');
      } else {
        this.$http.post(`/saved-models/${Message.hashid}/save/message`).then((res) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Message added to saved messages",
          //     icon: "CheckCircleIcon",
          //     variant: "primary",
          //   },
          // });
          this.$toast({
        component: ToastificationContent,
        props: {
          title: `Message added to saved messages`,
          icon:'CheckCircleIcon',
          variant : 'success',
          hideClose: true,

        },
      },
      {
        timeout : 2000,
        position : 'bottom-center',
        toastClassName:"successful-info",
        hideProgressBar : true,

      }) 
        });
        element.classList.remove('profile-icon');
        element.classList.add('saved-post');

      }
    },
  },
  data() {
    return {
      userData: this.$cookies.get("userData"),
      chatInputMessage: '',
      editMessageId: null,
      fullScreen: false,
    }
  }
}
</script>
  
<style>
.swal2-center {
  .swal2-popup {
    .swal2-html-container {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }
}

.swal2-content {
  margin: 0 4rem;
}</style>
  